@use "../globalConst" as g;

.footer {
  // background-color: yellow;
  width: 100%;
  height: 23px;
  bottom: 0;
  color: black;
  text-align: center;
  overflow: hidden; //this would remove the odd extra space at the end of the page, especially firefox
  /* background-color: rgb(243, 242, 242); */
}

@use "../globalConst" as g;

$photosize: 300px;

.selfIntroducecontainer {
  // background-color: yellow;
}

// .profilephoto {
//   height: $photosize;
//   width: $photosize;
//   border-radius: $photosize;
//   margin-top: 20%;
//   margin-left: 30%;
// }

.selfIntroduce {
  padding: 10%;
  font-family: g.$font_primary;
  font-size: 1.5rem;
}

@use "../globalConst" as g;

.ulList {
  padding: 10%;
  line-height: 60px;
  text-align: center;
  font-size: 26px;
  list-style-type: none;
}

.link,
.link:hover {
  font-family: g.$font_primary;
  text-decoration: none;
  color: white;
}

@media only screen and (max-width: 501px) {
  .link,
  .link:hover {
    color: black;
  }
}

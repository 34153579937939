@use "../globalConst" as g;

.navblock {
  background-color: g.$theme_secondary;

  margin: 0px 5px 0 5px; //with this margin value, the nav bar won't change line even the browser window is very narrow lol
  padding-top: 5px;
  width: 90px;
  height: 40px;
  text-align: center;
  text-decoration: none;
  color: white;
}

.navblock:hover {
  color: rgb(194, 193, 193);
}

.navarea {
  // background-color: red;
  display: flex;
  flex-wrap: nowrap;
  flex-flow: nowrap;
}

@media only screen and (max-width: 500px) {
  .navblock {
    background-color: g.$theme_secondary;

    margin: 0px 5px 0 5px; //with this margin value, the nav bar won't change line even the browser window is very narrow lol
    padding-top: 5px;
    width: 60px;
    height: 30px;
    text-align: center;
    text-decoration: none;
    color: white;
  }
}

body {
  font-family: "arial", -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  zoom: reset;
}

* {
  font-family: "arial", serif;
  /* overflow-y: hidden; */
}

body,
html {
  height: 100%;
  /* overflow-y: hidden; */
  overflow-x: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media (-webkit-device-pixel-ratio: 1.25) {
  * {
    zoom: 0.8;
  }
}

@media only screen and (max-width: 500px) {
  html {
    font-size: 10px;
  }
}

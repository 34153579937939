@use "../globalConst" as g;

.header {
  text-align: center;
  font-size: 3rem;
  // padding-top: 15px;
  // padding-bottom: 15px;

  color: g.$font_primary_color;
  font-family: g.$font_primary;

  // background-color: orange;
}

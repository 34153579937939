@use "../globalConst" as g;

$cardwidth: 170px;

.projectContainer {
  display: flex;
  flex-flow: wrap;
  // background-color: blue;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.coverImg {
  max-width: $cardwidth;
  max-height: 150px;
  width: auto;
  height: auto;

  // height: 150px;
  // width: $cardwidth;
}

.card {
  width: $cardwidth;
  height: 40px;
  font-size: 14px;
  margin: 0 10px 400px 10px;
}

.textArea {
  background-color: g.$theme_tertiary_light;
  padding: 7px;
  height: 300px;
}

.codeButton {
  color: white;
  background: g.$theme_tertiary_dark;
  text-decoration: none;
}

a.codeButton:hover {
  color: white;
}

.card-title {
  font-size: 17px;
  font-weight: bold;
}

.card-text {
  font-size: 15px;
}

@use "./globalConst" as g;

html,
body,
#app,
#app > div {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* to remove the auto padding added by the browser */
* {
  margin: 0;
  padding: 0;
}

.App {
  height: 100vh;
  margin: 0;
  padding: 0;
}

.Wrapper {
  display: flex;
  margin: 0 10% 0 10%;
  height: 100%;
  width: 80%;
  background-color: g.$theme_primary_light;
  // min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  flex: 1;
  overflow: visible;
}

.content {
  // background-color: green;
  margin: 5px 15% 5px 15%;
  // width: 1000px;
  // min-height: 60px;
  // overflow-x: auto;
  padding-top: 3px;
  height: 90%; //in order to adjust the space-between value between elements, you have to resize the elements themselves to modify the space in between
  width: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  // hide scrollbar for different browser
  scrollbar-width: none;
  -ms-overflow-style: none;
}

//hide scrollbar for different browser
.content::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 501px) {
  .content {
    margin: 5px 0 5px 0;
    // width: 1000px;
    // min-height: 60px;
    // overflow-x: auto;
    padding-top: 3px;
    height: 90%; //in order to adjust the space-between value between elements, you have to resize the elements themselves to modify the space in between
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    // hide scrollbar for different browser
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}
